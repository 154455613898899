import { useTranslation } from 'react-i18next'

const pathMappings = require('../../pathMapping.json')

const useLocalizedPath = () => {
  const { i18n } = useTranslation()

  const getLocalizedPath = (path: string, addSlash: boolean = true): string => {
    const localizedPath = pathMappings[path]?.[i18n.language]

    if (localizedPath) {
      return addSlash ? `/${localizedPath}` : localizedPath
    }

    return addSlash ? `/${path}` : path
  }

  return getLocalizedPath
}

export default useLocalizedPath
